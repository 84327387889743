<template>
  <div>Dashboard</div>
</template>

<script>
export default {
  name: "Dashboard",
  created() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>